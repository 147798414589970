html ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
html ::-webkit-scrollbar-track-piece {
  background-color: #eee;
  opacity: 0.9;
  border-radius: 6px;
  transition: width 0.2s linear, opacity 0.2s linear,
    background-color 0.2s linear;
}

html ::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 6px;
}

body {
  margin: 0;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
