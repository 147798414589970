/* Login styles */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_background {
  background-image: url(../assets/bg2.jpg);
}
.login_logo {
  display: flex;
  align-items: center;
}
.login-title {
  font-size: 40px;
  font-weight: 600;
}
.login_input {
  position: relative;
  margin: 20px auto;
}

.login_link {
  text-decoration: none;
  text-decoration-color: #143774;
}

.login_button {
  border-radius: 5px;
  border-width: 0px;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 250px;
  height: 40px;
  margin-bottom: 20px;
}
.login_wrapper {
  width: 350px;
  background: #fff;
  border-bottom: 3px solid #18c805;
  border-radius: 3px;
  box-shadow: 1px 1px 5px 0px rgb(36 181 11 / 30%);
  .supporter {
    text-align: end;
    margin: 5px 80px 15px auto;
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
  }
  .signup-wrapper {
    padding: 10px;
    // font-family: sanserif;
    background: #ebeaea;
  }
}

//frgot pwd
.new-user-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

//Dashboard header styles
.dashboard-menu {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

.side_menu {
  display: flex;
}

.MuiFormHelperText-root {
  color: #f95c00 !important;
  margin-left: 0 !important;
  font-weight: 600 !important;
}

.app-bar {
  background-color: #178b6e !important;
  height: 50px;
  color: #fff !important;
}

//dashboard content style
.submit-btns {
  display: flex;
  justify-content: center;
}
.add-prod-btn {
  margin: 10px !important;
}
.dashboard-links {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
}
.prod-container {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
}

.payment-input {
  margin: 15px auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.label {
  width: 150px;
  float: left;
}

// home style
.home-style {
  // width: "1100px",
  // height: 70vh;
  // margin: "50px 270px",
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 1px solid lightgrey;
  padding: 30px;
}
.image-size {
  width: 500px;
  height: 270px;
}
.home-box {
  position: relative;
  width: 500px;
  margin-top: 15px;
  height: auto;
}

// other styles
.height {
  height: 100vh;
}
.text-center {
  text-align: center;
}

.button-primary {
  background-color: #20a785 !important;
}

.input {
  margin: 7px 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.label {
  width: 150px;
  float: left;
}
.border {
  border-bottom: #707070 1px solid;
  color: #707070;
}

//ImageEntry styles
.outer-box {
  border: 1.5px solid #e0e0e0;
  width: 1030px;
  margin: auto 10px;
}

// AddImage styles
.heading {
  top: 10px;
  position: relative;
  background-color: white;
  margin: auto 20px;
  padding: 0 5px;
}
.image-container {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
}

// EditImage Styles
.grid-align {
  height: 71vh;
  width: 100%;
  margin-top: 22px;
  margin-right: 10px;
}
.icon {
  color: #20a785 !important;
}

// tab style

.tabs-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%; /* Set the height as needed */
  // display: flex;
  // flex-direction: column;
}

.tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
}

.tab {
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
  /* Add other styles as needed */
}

ul {
  list-style-type: none !important;
}

.list-style {
  display: flex;
  justify-content: space-between;
  width: 60vw;
  margin-top: 30px;
  margin-left: 35px;
  box-shadow: 0px 0px 2px 1px rgb(174 174 174);
}

.list-card-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.list-textbox {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.css-187mznn-MuiSlider-root {
  color: #178b6e !important;
}

.slick-next {
  right: 0px;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #178b6e !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #178b6e !important;
}

.carousel .thumbs-wrapper {
  display: none;
}

// profile style
.profile-style {
  width: 70vw;
  height: 69vh;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px solid lightgrey;
}

.edit-image-container {
  position: relative;
  width: 300px; /* Adjust width and height as needed */
  height: 300px;
}

.edit-icon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  padding: 5px;
  display: none; /* Hidden by default */
}

.edit-image-container:hover .edit-icon {
  display: block; /* Show edit icon on hover */
}

// preview list
.preview-list {
  display: flex;
  justify-content: space-between;
  // width: 1000;
  margin-top: 30px;
  // margin-left: 35px;
  box-shadow: 0px 0px 2px 1px rgb(174 174 174);
}
.preview-list-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.css-1128ygc-MuiTabs-root{
  background-color:#ecfaf6 !important;
}
// mobile view

@media (max-width: 1024px) {
  .home-style {
    flex-direction: column;
    width: 95vw;
    height: "100vh";
    padding: 10px; // padding-top: 10px;
    // margin-bottom: 100px;
    // margin: 5px 30px;
    align-items: center; /* Optional: Center the content in the column */
  }
  .image-container {
    flex-direction: column;
  }
  .outer-box {
    width: 95vw;
  }
  .tabs-container {
    height: 0; /* Set the height as needed */
  }
  .tabs {
    // flex-direction: column;
    background-color: #178b6e;
    position: absolute;
    z-index: 800;
  }
  // .tab {
  //   font-weight: bold;
  //   font-size: 15px;
  //   padding: -10px;
  //   cursor: pointer;
  //   /* Add other styles as needed */
  // }
  ul {
    margin-left: -40px;
    // margin-right: 0px;
  }
  .list-textbox {
    width: "90vw";
    flex-direction: column;
    justify-content: center;
  }
  .list-style {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 95vw;
    // marginLeft: "15px",
    // flex-direction: column;
  }
}

@media (max-width: 600px) {
  /* Switch to a column layout in mobile view */
  .home-style {
    flex-direction: column;
    width: 95vw;
    padding: 0px;
    // height: 81vh;
    // padding-top: 10px;
    // margin-bottom: 100px;
    // margin: 5px 30px;
    align-items: center; /* Optional: Center the content in the column */
  }
  .list-style {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 90vw;
    // marginLeft: "15px",
    flex-direction: column;
  }

  .list-card-content {
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    padding-bottom: 20px;
  }
  .image-container {
    flex-direction: column;
  }

  .tabs-container {
    height: 0; /* Set the height as needed */
  }
  .tabs {
    // flex-direction: column;
    background-color: #178b6e;
    position: absolute;
    z-index: 1000;
  }
  ul {
    margin-left: -40px;
    // margin-right: 0px;
  }
  .image-size {
    width: 300px;
    height: 200px;
  }
  .home-box {
    position: relative;
    width: 300px;
    margin-top: 0px;
  }
  .outer-box {
    width: 95vw;
  }
  .preview-list {
    flex-direction: column;
    align-content: center;
  }
  .preview-list-content {
    flex-direction: column;
  }
  .list-textbox {
    width: "90vw";
    flex-direction: column;
    justify-content: center;
  }
}
