.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toggle-button-cover {
  display: inline-block;
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #0bebeb;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #03f48033;
  transition: 0.3s ease all;
  z-index: 1;
}

#button-2 .knobs:before,
#button-2 .knobs:after {
  content: "Dine-In";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 40px;
  height: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #03f480;
  border-radius: 50px;
  transition: 0.3s ease all;
  border: 1px solid #d0d3d1;
}

#button-2 .knobs:before {
  content: "Dine-In";
}

#button-2 .knobs:after {
  content: "Online";
}

#button-2 .knobs:after {
  right: -50px;
  left: auto;
  background-color: #ffab00;
}

#button-2 .checkbox:checked + .knobs:before {
  left: -50px;
}

#button-2 .checkbox:checked + .knobs:after {
  right: 4px;
}

#button-2 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

.radio-input input {
  display: none;
}

.radio-input {
  --container_width: 470px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 9999px;
  background-color: #fff;
  color: #000000;
  width: var(--container_width);
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio-input label {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-weight: 600;
  letter-spacing: -1px;
  font-size: 14px;
}

.selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 5);
  z-index: 0;
  left: 0;
  top: 0;
  transition: 0.15s ease;
}

.radio-input label:has(input:checked) {
  color: #fff;
}

.radio-input label:has(input:checked) ~ .selection {
  background-color: rgb(0 195 146);
  display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 0 / 5));
}

.radio-input label:nth-child(2):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 1 / 5));
}

.radio-input label:nth-child(3):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 2 / 5));
}

.radio-input label:nth-child(4):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 3 / 5));
}

.radio-input label:nth-child(5):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 4 / 5));
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_box {
  // width: 200px;
  // height: 250px;
  // border-radius: 20px;
  // background: linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%);
  position: relative;
  // box-shadow: 0 25px 50px rgba(0,0,0,0.55);
  // cursor: pointer;
  // transition: all .3s;
}

// .card_box:hover {
//   transform: scale(0.9);
// }

.card_box .offer {
  position: absolute;
  overflow: hidden;
  width: 150px;
  height: 150px;
  top: -10px;
  left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_box .offer::before {
  content: "Discount";
  position: absolute;
  width: 150%;
  height: 40px;
  background-image: linear-gradient(
    45deg,
    #ff6547 0%,
    #ffb144 51%,
    #ff7053 100%
  );
  transform: rotate(-45deg) translateY(-20px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
}

.card_box .offer::after {
  content: "";
  position: absolute;
  width: 10px;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: -1;
  box-shadow: 140px -140px #cc3f47;
  background-image: linear-gradient(
    45deg,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  );
}

.Mui-completed {
  color: #178b6e !important;
}

.Mui-active {
  color: coral !important;
}

.Mui-selected {
  background-color: #9cedd6 !important;
  border-radius: 3px !important;
}

.MuiTabs-indicator {
  border-bottom: 3.5px solid #ec0d0d !important;
}
